import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, ErrorHandler as AngularErrorHandler,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  AsyncPipe,
  KeyValuePipe,
  Location,
  LowerCasePipe,
  NgClass,
  NgFor,
  NgIf,
  NgStyle,
  NgTemplateOutlet,
  TitleCasePipe
} from '@angular/common'
import {Store} from '@ngxs/store';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterLink} from '@angular/router';
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbModal,
  NgbNav,
  NgbNavContent,
  NgbNavItem,
  NgbNavItemRole,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavOutlet,
  NgbOffcanvas,
  NgbPopover,
  NgbTooltip
} from '@ng-bootstrap/ng-bootstrap';
import {combineLatest, firstValueFrom, forkJoin, Observable, of, Subject} from 'rxjs';
import {DragulaService} from 'ng2-dragula';
import {ToastrService} from 'ngx-toastr';

import * as _ from 'lodash';

import {AppState} from '../../state/app.state';
import {RecordPage} from '../../state/app.actions';
import {UserService} from '../../services/user.service';
import {RecordService} from '../../services/record.service';
import {ChoiceService} from 'src/app/services/choice.service';
import {
  applyApplicationStyle,
  computeColumns,
  computeGridColumnsClasses,
  getActiveFilters,
  isTouchScreen,
  openDetailPage,
  stripBaseUrl
} from "../../app.utils";
import {debounceTime, filter, take, tap} from 'rxjs/operators';
import {WebSocketService} from 'src/app/services/websocket.service';
import {LocalService} from 'src/app/services/local.service';
import {RecordPageState, widgets} from '../../state/record-page.state';
import {translate, TranslocoDirective} from '@jsverse/transloco';
import party from "party-js";
import {PanZoomAPI, PanZoomComponent, PanZoomConfig, PanZoomConfigOptions, PanZoomModel} from 'ngx-panzoom';
import {ModalProxyService} from "../../services/modal-proxy.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {PrioritynavSelectors} from 'src/app/directives/prioritynav.directive';
import {OrderByVisiblePipe} from '../../pipes/order-by-visible.pipe';
import {FlattenBlocksPipe} from '../../pipes/flatten-blocks.pipe';
import {ExistPipe} from '../../pipes/exist.pipe';
import {ExcludePipe} from '../../pipes/exclude.pipe';
import {SpecialTitleCasePipe} from '../../pipes/special-title.pipe';
import {NgArrayPipesModule, NgObjectPipesModule, NgStringPipesModule} from 'ngx-pipes';
import {ConfirmationComponent} from '../../components/confirmation/confirmation.component';
import {ActivitiesComponent} from '../../components/activities/activities.component';
import {AttachmentsComponent} from '../../components/attachments/attachments.component';
import {CommentsComponent} from '../../components/comments/comments.component';
import {WidgetComponent} from '../../components/widget/widget.component';
import {ChecklistComponent} from '../../components/checklist/checklist.component';
import {AuditComponent} from '../../components/audit/audit.component';
import {FieldManyComponent} from '../../components/field-many/field-many.component';
import {FieldComponent} from '../../components/field/field.component';
import {FormComponent} from '../../components/form/form.component';
import {LoaderComponent} from '../../components/loader/loader.component';
import {PrioritynavDirective} from '../../directives/prioritynav.directive';
import {ActionComponent} from '../../components/action/action.component';
import {TrackDirective} from '../../directives/track.directive';
import {CheckDirective} from '../../directives/check.directive';
import {FieldMainComponent} from '../../components/field-main/field-main.component';
import {IconComponent} from '../../components/icon/icon.component';
import {DefaultComponent} from '../../components/default/default.component';
import {NgxIndexedDBService} from "ngx-indexed-db";


declare var $: any;

@Component({
    selector: 'app-record',
    templateUrl: './record.component.html',
    styleUrls: ['./record.component.scss'],
    viewProviders: [DragulaService],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
  imports: [TranslocoDirective, DefaultComponent, NgIf, NgbPopover, IconComponent, NgTemplateOutlet, FieldMainComponent, CheckDirective, TrackDirective, NgFor, ActionComponent, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbTooltip, NgClass, NgbDropdownItem, PanZoomComponent, PrioritynavDirective, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, RouterLink, NgbNavContent, LoaderComponent, NgbNavOutlet, FormComponent, NgStyle, FieldComponent, FieldManyComponent, AuditComponent, ChecklistComponent, WidgetComponent, CommentsComponent, AttachmentsComponent, ActivitiesComponent, ConfirmationComponent, AsyncPipe, LowerCasePipe, TitleCasePipe, KeyValuePipe, NgArrayPipesModule, NgStringPipesModule, SpecialTitleCasePipe, ExcludePipe, ExistPipe, FlattenBlocksPipe, OrderByVisiblePipe, NgObjectPipesModule]
})
export class RecordComponent implements OnInit, OnDestroy, AfterViewInit {

  readonly #takeUntilDestroyed = takeUntilDestroyed();

  get tabsCount$(): Observable<any> {
    return this.store.select(RecordPageState.tabsCount(this.recordId));
  }

  get recordPage$(): Observable<any> {
    return this.store.select(AppState.recordPage(this.recordId));
  }

  @Input() isModal = false;
  @Input() modalWindow = null;
  @Output() closeModal = new EventEmitter();


  @ViewChild('nav') ngbNav;
  @ViewChild('DetailPageModal') detailPageModal;
  @ViewChildren('fieldMany') fieldMany: QueryList<ElementRef>;
  @ViewChildren("blockElementsRef", {read: ElementRef}) blockElementsRef!: QueryList<ElementRef>;

  @HostListener('window:resize')
  public onResize() {
    if (this.panZoomAPI) {
      this.panZoomAPI.resetView();
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.closeDetailPageModal("popstate");
  }

  reInit$: Subject<any> = new Subject();
  recalculatePrioritynav: Subject<any> = new Subject();

  space = this.localService.getSpace();
  panZoomOptions: PanZoomConfigOptions = {
    zoomOnMouseWheel: false,
    zoomButtonIncrement: 0.25,
    freeMouseWheelFactor: 0.0005,
    friction: 50,
    haltSpeed: 150,
    zoomOnDoubleClick: false,
    panOnClickDrag: isTouchScreen(),
    noDragFromElementClass: isTouchScreen() ? 'create-container' : 'block'
  }
  panZoomConfig: PanZoomConfig = new PanZoomConfig(this.panZoomOptions);
  panelIsFullScreen = false;
  loading = {
    id: -1,
    type: ''
  };

  currentPanZoomLevel;
  executingActionId;
  confirmationModal;
  formModal;
  readForm;
  panZoomAPI;
  recordId;
  activeSiderBlock;
  hiddenTabs: {[parentBlockId: number]: any[]} = {};
  tabbedBlocksWithFieldsOrWidgets: any[] = [];

  widgetsData = {};
  widgetsErrors = {};
  widgetsActiveGlobalFilters: any = {};
  widgetsGlobalFilters: any  = {};

  public get PriorityNavSelectors() {
    return PrioritynavSelectors;
  }

  private shouldDestroyRecordState = true;

  constructor(private modalService: NgbModal,
              public userService: UserService,
              public modalProxyService: ModalProxyService,
              private webSocketService: WebSocketService,
              private localService: LocalService,
              public titleCasePipe: TitleCasePipe,
              private router: Router,
              private route: ActivatedRoute,
              private store: Store,
              public toastr: ToastrService,
              private recordService: RecordService,
              private location: Location,
              public changeDetector: ChangeDetectorRef,
              public choiceService: ChoiceService,
              public offcanvasService: NgbOffcanvas,
              private breakpointObserver: BreakpointObserver,
              private dbService: NgxIndexedDBService,
              private injector: Injector
  ) {
    this.reInit$.pipe(this.#takeUntilDestroyed).pipe(
      debounceTime(100),
      tap((params: any) => {
        if (params.trigger && this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId)).record) {
          const {record, tab} = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId));
          this.store.dispatch(new RecordPage.Init(record.id, tab, true));
          this.changeDetector.detectChanges();
        }
      })
    ).subscribe();
  }

  ngOnInit() {
    this.webSocketService.messages.pipe(
      filter(res => (res !== null && res.message.type === 'highlight'))).pipe(
      this.#takeUntilDestroyed).subscribe((res: any) => {
      const content = res.message.data;
      if (content.actor && content.actor !== this.localService.getUser()) {
        if (content.record && content.record === parseInt(this.recordId)) {
          if (!(this.router.url !== this.location.path() && !this.isModal)) {
            this.store.dispatch(new RecordPage.Init(this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId)).record.id, this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId)).tab, true));
          }
        }
      }
    });
    this.webSocketService.messages.pipe(
      filter(res => (res !== null && ['edition', 'action', 'audit'].includes(res.message.type)))).pipe(
      this.#takeUntilDestroyed).subscribe((res: any) => {
      if (['edition', 'audit'].includes(res.message.type)) {
        const sameUserAndRecord = res.message.user === this.userService.localService.getUser() &&
          res.message.sender.id === this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId)).record?.id;
        if (res.message.method !== 'DELETE' && !sameUserAndRecord) {
          if (res.message.response.staleComments){
            this.store.dispatch(new RecordPage.RetrieveTabObjects(this.recordId, {type: 'checkpointDiscussion', id: res.message.response.id, ready: true}, 1));
          } else {
            this.refreshFromWS(res);
          }
        }
      } else {
        this.refreshFromWS(res);
      }
    });
    this.webSocketService.messages.pipe(
      filter(res => (res !== null && res.message.type === 'sync'))).pipe(
      this.#takeUntilDestroyed).subscribe((res: any) => {
      this.refreshFromWS(res);
    });
    this.webSocketService.messages.pipe(
      filter(res => (res !== null && res.message.type === 'data' && res.message.record == this.recordId))).pipe(
      this.#takeUntilDestroyed).subscribe((res: any) => {
      this.refreshWidgetData(res.message);
    });

    this.panZoomConfig.modelChanged.pipe(this.#takeUntilDestroyed).subscribe((model: PanZoomModel) => {
      this.currentPanZoomLevel = model.zoomLevel;
    });
    this.localService.siderVisibility$.pipe(this.#takeUntilDestroyed).subscribe((val) => {
      if (this.panZoomAPI && this.currentPanZoomLevel === this.panZoomConfig.initialZoomLevel) {
        this.panZoomAPI.resetView();
      }
    });
    if (!this.isModal) {
      combineLatest([this.route.params, this.route.queryParams]).pipe(this.#takeUntilDestroyed).subscribe(([params, queryParams]) => {
        let id = params.id ? params.id : this.recordId;
        this.init(id, params.tab);
      });
    }

    if (this.isModal) {
      this.route.paramMap.subscribe(params => {
        this.init(params.get('id'), params.get('tab'));
      });
    }

    this.router.events.pipe(this.#takeUntilDestroyed).subscribe(event => {
      if (event instanceof NavigationStart) {
        const urlParts = event.url.split('/');
        const nextRecordId = urlParts[2];
        nextRecordId !== this.recordId ? this.shouldDestroyRecordState = true : this.shouldDestroyRecordState = false;
      }
    });
    this.recordPage$.pipe(
      this.#takeUntilDestroyed,
      debounceTime(100)
    ).subscribe((state: any) => {
      if(state.isReady){
        this.tabbedBlocksWithFieldsOrWidgets = _.map(_.filter(
          state.entity.blocks, b => (
            b.isTabbed
            && (
              b.widgets?.length > 0
              || _.filter(b.fields, f =>
                  f.id !== state.entity.mainField?.id
                  && !f?.isMany
                  && _.find(state.record.fields, { 'id': f.id, 'isActiveDetail': true })
              )?.length > 0
            )
        )), blc => ({id: blc.id, widgets: blc.widgets}))
      }
    })
  }

  ngAfterViewInit() {
    this.recordPage$.subscribe(state => {
      if (state.isReady) {
        let style = this.store.snapshot().app?.sider?.user?.space?.style;
        if (style) {
          applyApplicationStyle(style);
        }

        if (state.entity.isPanel){
          setTimeout(() => {
            this.panelConfig(state.entity.resolution);
            this.setSeparators(this.blockElementsRef);
          }, 600);

          let intervalId = setInterval(() => {
            let el = document.getElementsByClassName('pan-zoom-frame');
            if (el.length !== 0) {
              clearInterval(intervalId);
              el[0]['style'].overflowY = 'scroll'
            }
          }, 800);
        }else{
          setTimeout(() => {
            const widgets = _.flatMap(state.entity.blocks, (b) => b.widgets);
            this.setSeparatorsForTabbedBlocksWidgets(widgets);
          }, 600)
        }
      }
    })
    this.recalculateSeparatorsOnBreakpoints();
  }

  ngOnDestroy() {
    if(this.shouldDestroyRecordState){
      this.store.dispatch(new RecordPage.Destroy(this.recordId));
    }
    const urlTree = this.router.parseUrl(this.router.url);
    const primary = urlTree.root.children['primary'];
    const auxiliary = urlTree.root.children['modal'];
    if (auxiliary) {
      const records = this.store.selectSnapshot((state) => state.app.recordPage.records);
      const parentRecordId = _.head(_.keys(records));
      if (_.keys(records).length === 1 && records[parentRecordId]?.record == null) {
        let tabId = primary.segments[2]?.path;
        this.init(parentRecordId, tabId);
      } else {
        _.forEach(records, (value, key) => {
          const {record, tab} = value;
          this.store.dispatch(new RecordPage.Init(record.id, tab, true));
        });
      }
    }
  }

  init(recordId, tab) {
    // prevent scroll to top while changing tab
    let outlet = false;
    // Check if the current route has the outlet property set to 'modal'
    this.route.url.subscribe(segments => {
      outlet = segments.some(segment => segment.path === 'modal');
    });
    if (!this.recordId && !tab && !outlet) {
      const scrollY = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId)).record?.id === +recordId ? window.scrollY : 0;
      setTimeout(() => {
        window.scrollTo(0, scrollY);
      }, 0);
    }
    this.recordId = recordId;
    this.store.dispatch(new RecordPage.Init(recordId, tab)).pipe(this.#takeUntilDestroyed).subscribe((res: any) => {
      this.recalculatePrioritynav.next(true);
      if (this.route.snapshot.fragment) {
        this.checkElement('record-' + this.route.snapshot.fragment).then(() => {
          this.scrollToTargetAdjusted('record-' + this.route.snapshot.fragment);
        }).catch(() => {
        });
      }
      const recordState = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(recordId));
      const auditId = recordState.record?.fixedCheckpoint?.checkpointset.auditRecord;
      const auditState = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(auditId));
      if (recordState.entity.dasboardReadForm){
        firstValueFrom(this.dbService.getByIndex("widgetsFilters", 'id', _.toNumber(`${this.recordId}${recordState.entity.id}`))).then((globalFilters: any)=>{
          if (globalFilters) {
            this.widgetsGlobalFilters = globalFilters.filters;
            this.widgetsActiveGlobalFilters = getActiveFilters(this.widgetsGlobalFilters.rawData, this.widgetsGlobalFilters.types);
          }
        });
      }
      if (this.isModal && auditId && !!!auditState.record){
        this.store.dispatch(new RecordPage.Init(auditId));
      }
    }, (err) => {
      if (err.status === 404) {
        for (const toast in this.toastr.toasts) {
          if (this.toastr.toasts[toast].message === 'Pas trouvé.') {
            this.toastr.remove(this.toastr.toasts[toast].toastId);
          }
        }
      }
    });
  }

  onChecklistObjectDelete(type, id) {
    if (this.confirmationModal) {
      this.confirmationModal.close();
      this.confirmationModal = null;
    }
    this.loading = {id, type};

    this.store.dispatch(new RecordPage.DeleteChecklistObject(this.recordId, type, id)).subscribe(res => this.loading = {
      id: -1,
      type: ''
    });
  }

  onDeleteCheckpoint(checkpointToDelete) {
    this.onChecklistObjectDelete('checkpoints', checkpointToDelete.id);
  }

  onDeleteCheckpointset(checkpointsetToDelete) {
    this.onChecklistObjectDelete('checkpointsets', checkpointsetToDelete.id);
  }

  openImportCheckpointsModal(evt) {
    let content = evt.content;
    let checkpointsets = evt.checkpointsets;
    if (checkpointsets.length) {
      this.modalService.open(content).result.then((result) => {
        const lastCheckpointset = checkpointsets[checkpointsets.length - 1];
        const checkpoints = result.split('\n').filter((checkpoint) => checkpoint != '');
        let lastPosition = lastCheckpointset.checkpoints.length ? lastCheckpointset.checkpoints[lastCheckpointset.checkpoints.length - 1].position + 1 : 1;
        const newCheckpoints = [];
        for (const checkpoint of checkpoints) {
          // let position = newCheckpoints.length ? newCheckpoints[newCheckpoints.length-1].position + 1 : lastPosition;
          newCheckpoints.push({
            title: checkpoint,
            position: lastPosition++,
            checkpointAttachments_: [],
            checkpointset: lastCheckpointset.id
          });
        }
        this.store.dispatch(new RecordPage.UpdateChecklistObjects(this.recordId, 'checkpoints', newCheckpoints)).subscribe(res => {
          this.toastr.success(translate('Points de contrôle ajoutés'));
        });
        // this.checkpointsStr = '';
      }, (reason) => {

      });
    }
  }

  OnOpenImportChoicesModal(evt) {
    let content = evt.content;
    let checkpoint = evt.checkpoint;
    this.modalService.open(content).result.then((result) => {
      if (result) {
        const choices = result.split('\n').filter((choice) => choice !== '').map((line) => line.split(','));
        const newChoices = [];
        let notes  = checkpoint.notes?.notes;
        for (const choice of choices) {
          if (!newChoices.some(c => c.title === choice[0]) && !checkpoint.choices?.some(c => c.title === choice[0])) {
            newChoices.push({
              title: choice[0],
              title_fr: choice[0],
              name: choice[0],
              checkpoint: checkpoint.id,
            });
          }
          let newNote = {
            "note": parseInt(choice[1], 10),
            "choice": choice[0]
          }
          notes = notes ? [...notes, newNote]: [newNote];
        }
        result = {
          "notes": notes
        };
        if (checkpoint.type === 'Multi Select') {
          result["aggregation"] = checkpoint.notes?.aggregation;
        }
        result['ranges'] = checkpoint.notes?.ranges;
        if (newChoices.length > 0) {
          this.store.dispatch(new RecordPage.CreateCheckpointChoice(this.recordId, checkpoint.id, newChoices)).subscribe(res => {
            this.toastr.success(translate('choix ajoutés'));
          });
        }
        this.store.dispatch(new RecordPage.UpdateCheckpointNotes(this.recordId, checkpoint, result));

      }
    });
  }

  openCreateCheckpointsetModal(evt) {
    let content = evt.content
    let record = evt.record
    this.modalService.open(content).result.then((result) => {
      const object = {
        title: result,
        position: record.originalCheckpointsets.length ? record.originalCheckpointsets[record.originalCheckpointsets.length - 1].position + 1 : 0,
        checklistRecord: record.id,
        checkpoints: []
      };
      this.store.dispatch(new RecordPage.CreateChecklistObject(this.recordId, 'checkpointsets', object)).subscribe(res => {
        this.toastr.success(translate('Section ajoutée'));
      });
      // this.checkpointsetStr = '';
    }, (reason) => {
    });
  }

  onDragulaChecklistMove(evt: any) {
    this.store.dispatch(new RecordPage.UpdateChecklistObjects(this.recordId, evt.type, evt.value));
  }

  onCreateSubmit(evt) {
    let record = evt.record
    let object;
    const lastCheckpointset = record.originalCheckpointsets[record.originalCheckpointsets.length - 1];
    object = {
      title: evt.value,
      position: lastCheckpointset.checkpoints.length ? lastCheckpointset.checkpoints[lastCheckpointset.checkpoints.length - 1].position + 1 : 0,
      checkpointAttachments_: [],
      checkpointset: lastCheckpointset.id
    };
    this.store.dispatch(new RecordPage.CreateChecklistObject(this.recordId, 'checkpoints', object)).subscribe(res => {
      this.toastr.success(translate('Point de contrôle ajouté'));
    });
  }

  onChecklistObjectTitleChange(evt) {
    let type = evt.type;
    let object = evt.object;
    let title = evt.title;
    this.store.dispatch(new RecordPage.UpdateChecklistObject(this.recordId, type, {id: object.id, title}));
  }

  onUpdateCheckpointType(evt: any) {
    this.store.dispatch(new RecordPage.UpdateCheckpointType(this.recordId, evt.checkpoint, evt.type));
  }

  onToggleCheckpointTag(evt: any) {
    this.store.dispatch(new RecordPage.ToggleCheckpointTag(this.recordId, evt.checkpoint, evt.tag));
  }

  onCreateCheckpointTag(evt: any) {
    this.store.dispatch(new RecordPage.CreateCheckpointTag(this.recordId, evt.extension, evt.tag));
  }

  onDeleteCheckpointTag(evt: any) {
    this.store.dispatch(new RecordPage.DeleteCheckpointTag(evt.recordId, evt.checkpointId, evt.tag));
  }

  onCreateCheckpointChoice(evt: any) {
    this.store.dispatch(new RecordPage.CreateCheckpointChoice(evt.recordId, evt.checkpointId, evt.choice));
  }

  onUpdateCheckpointChoice(evt: any) {
    this.store.dispatch(new RecordPage.UpdateCheckpointChoice(evt.recordId, evt.checkpointId, evt.choice))
  }

  onDeleteCheckpointChoice(evt: any) {
    this.store.dispatch(new RecordPage.DestroyCheckpointChoice(evt.recordId, evt.choice));
  }

  onUpdateCheckpointNotes(evt: any) {
    this.store.dispatch(new RecordPage.UpdateCheckpointNotes(evt.recordId, evt.checkpoint, evt.notes))
  }

  onLoadMore(evt, field) {
    this.store.dispatch(new RecordPage.RetrieveTabObjects(this.recordId, field, evt.page));
  }

  onCardsFilter(evt) {
    let filters = {};
    for (const key in evt.filters) {
      const values = evt.filters[key];
      filters[key] = (values && values.length !== 0) ? (values.length === 1 ? values[0] : values) : null;
    }
    filters = _.pickBy(filters, value => value !== null);
    this.store.dispatch(new RecordPage.UpdateCardsFilter(this.recordId, evt.fieldId, filters));
  }

  onFieldUpdate(evt) {
    let subject;
    if (evt.field.type === 'Checkbox') {
      subject = this.store.dispatch(new RecordPage.UpdateCheckboxField(this.recordId, evt.field.id, evt.value));
    } else {
      subject = this.store.dispatch(new RecordPage.UpdateField(this.recordId, evt.field, evt.value));
    }
    subject.subscribe(
      () => {
      },
      (e) => {
        if (e.status === 400) {
          this.ngOnInit();
        }
      });
  }

  onActionExecute(evt) {
    if (evt.confirmationModal) {
      evt.confirmationModal.close();
    }
    this.executingActionId = evt.action.id;
    this.store.dispatch(new RecordPage.ExecuteAction(this.recordId, evt.action, evt.inp, evt.args)).subscribe({
      next: () => {
        if (evt.action.isCelebrated == true) {
          party.confetti(document.getElementById("pageDetail"), {
            count: party.variation.range(150, 250),
            shapes: ["star", "roundedSquare"],
          });
        }
        if (evt.intermediateModal) {
          evt.intermediateModal.close();
        }
        const commandResult = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId)).commandResult;
        if (commandResult && commandResult.message) {
          this.toastr.success(commandResult.message);
        } else {
          this.toastr.success(translate('Opération effectuée') + ' !');
        }
        if (commandResult && commandResult.file) {
          const htmlAnchorElement = document.createElement("a");
          htmlAnchorElement.href = "data:application/octet-stream;base64," + commandResult.file;
          htmlAnchorElement.download = commandResult.filename ? commandResult.filename : "file";
          htmlAnchorElement.click();
          htmlAnchorElement.remove();
        }
        this.executingActionId = null;
        if (commandResult && commandResult.url) {
          this.closeDetailPageModal();
          if (!commandResult.url.startsWith('/')) {
            window.open(commandResult.url);
          } else {
            if (commandResult.url.indexOf('record/') !== -1) {
              const recordId = commandResult.url.split('record/')[1];
              this.recordService.retrieveObject(recordId, ['entity']).subscribe(value => {
                this.closeDetailPageModal();
                let evt = {recordId: recordId, openModal: value.entity.isDetailPageInModal};
                openDetailPage(evt);
              });
            } else {
              this.router.navigate([commandResult.url]).then(r => {
              });
            }
          }
        }
      },
      error: (err) => {
        this.executingActionId = null;
      }
    });
  }

  onRecordCreateSubmit(evt, field) {
    const entity = field.mirrorFields[0].entity;
    let fieldsValues;
    if (evt?.hasOwnProperty('fieldsValues')) {
      fieldsValues = evt.fieldsValues
    } else if (entity.creationForm) {
      fieldsValues = evt
    } else if (entity.mainField) {
      fieldsValues = {
        [entity.mainField.id]: evt
      }
    }
    this.store.dispatch(new RecordPage.CreateRecord(this.recordId, fieldsValues, field, evt?.indexGroupBy)).subscribe((res) => {
      const state = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId));
      if (!field.isRedirectAfterCreate && state?.many[field.id].mode === 'cards') {
        const newRecordId = state?.many[field.id]?.records[0]?.id;
        this.checkElement('record-' + newRecordId).then(() => {
          document.getElementById('record-' + newRecordId).getElementsByClassName('card-body')[0].classList.add('card-highlight');
        }).catch(() => {
        });
      }
      let displayedValue = entity.mainField ? fieldsValues[entity.mainField?.id] : null
      //Check if main field is User
      if (displayedValue?.fullName) {
        displayedValue = displayedValue.fullName
      }
      let fieldMany = this.fieldMany.find((el:any) => el.field.id === field.id);
      if (field.mirrorFields[0].entity.creationForm) {
        this.toastr.success(translate("record ajouté", {
          e: field.mirrorFields[0].entity.isMasculine ? '' : 'e',
          title: field.mirrorFields[0].entity.title
        }));

        if (!evt.hasOwnProperty('fieldsValues')) {
          fieldMany['create']['CreateModal'].dismiss();
        } else {
          fieldMany['timeline'].create.CreateModal.dismiss();
        }
      } else {
        this.toastr.success(displayedValue, translate("record ajouté", {
          e: field.mirrorFields[0].entity.isMasculine ? '' : 'e',
          title: field.mirrorFields[0].entity.title
        }));
      }
      if (fieldMany['timeline']) fieldMany['timeline'].cancelCreate();
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          take(1)
        )
        .toPromise()
        .then(() => {
          if (!field.mirrorFields[0].entity.isDetailPageInModal) {
            this.closeDetailPageModal();
          }
        });
    }, () => {
    });
  }

  onRecordAddSubmit(evt, field) {
    this.store.dispatch(new RecordPage.AddRecord(this.recordId, evt, field)).subscribe(() => {
      if (field.mirrorFields[0].entity.creationForm) {
        this.toastr.success(translate("record ajouté", {
          e: field.mirrorFields[0].entity.isMasculine ? '' : 'e',
          title: this.titleCasePipe.transform(field.mirrorFields[0].entity.title)
        }));
      } else {
        //TO DO afficher le titre de champ
        this.toastr.success(evt.value[Object.keys(evt.value)[0]], translate("record ajouté", {
          e: field.mirrorFields[0].entity.isMasculine ? '' : 'e',
          title: this.titleCasePipe.transform(field.mirrorFields[0].entity.title)
        }));
      }
    }, () => {
    });
  }

  onRecordRemoveSubmit(evt, field) {
    this.store.dispatch(new RecordPage.RemoveRecord(this.recordId, evt, field)).subscribe(() => {
      if (field.mirrorFields[0].entity.creationForm)
      {
        this.toastr.info(translate("record supprimé",{e:field.mirrorFields[0].entity.isMasculine ? '' : 'e',title:this.titleCasePipe.transform(field.mirrorFields[0].entity.title)}));
      }else {
        //TO DO afficher le titre de champ
        this.toastr.info(evt.value[Object.keys(evt.value)[0]], translate("record supprimé",{e:field.mirrorFields[0].entity.isMasculine ? '' : 'e',title:this.titleCasePipe.transform(field.mirrorFields[0].entity.title)}));
      }
    }, () => {
    });
  }

  onRecordDestroyConfirmationOpen(confirmationRef) {
    this.confirmationModal = this.modalService.open(confirmationRef);
    if (this.panelIsFullScreen) {
      document.getElementById('pageDetail').appendChild(document.querySelector('ngb-modal-backdrop'))
      document.getElementById('pageDetail').appendChild(document.querySelector('ngb-modal-window'))
    }
  }

  onRecordDestroyConfirmationClose() {
    this.confirmationModal.dismiss();
  }

  onRecordDestroy() {
    this.destroyRecord(this.recordId);
  }

  onCommentCreate(evt) {
    let object = evt.isCheckpoint
                 ? evt.checkpoint
                 : this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId)).record;
    let text = evt.isCheckpoint? evt.text : evt;
    this.store.dispatch(new RecordPage.CreateComment(object, this.recordId, text, evt.isCheckpoint)).subscribe(
      res => {
        const newCommentId = res.app.recordPage?.record?.comments[0]?.id;
        this.checkElement('comment-' + newCommentId).then(() => {
          document.getElementById('comment-' + newCommentId).classList.add('comment-read-highlight');
        }).catch(() => {
        });
      }, () => {
      }
    );
  }

  onCommentUpdate(evt) {
    let value = evt.value;
    let commentId = evt.commentId;
    let object = evt.isCheckpoint
                 ? evt.checkpoint
                 : this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId)).record;
    this.store.dispatch(new RecordPage.UpdateComment(object, value, this.recordId, commentId, evt.isCheckpoint)).subscribe(
      res => {
        this.checkElement('comment-' + commentId).then(() => {
          document.getElementById('comment-' + commentId).classList.add('comment-read-highlight');
        }).catch(() => {
        });
      }, () => {
      }
    );
  }

  onCommentDestroy(evt) {
    let object = evt.isCheckpoint
                 ? evt.checkpoint
                 : this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId)).record;
    this.store.dispatch(new RecordPage.DestroyComment(object, this.recordId, evt.commentToDelete.id, evt.isCheckpoint)).subscribe(
      () => {
      },
      () => {
      }
    );
  }

  onCommentReactionUpdate(evt) {
    let value = evt.value;
    let commentId = evt.commentId;
    let object = evt.isCheckpoint
                 ? evt.checkpoint
                 : this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId)).record;
    this.store.dispatch(new RecordPage.UpdateReaction(object, value, this.recordId, commentId, evt.isCheckpoint)).subscribe(
      res => {
        this.checkElement('comment-' + commentId).then(() => {
          document.getElementById('comment-' + commentId).classList.add('comment-read-highlight');
        }).catch(() => {
        });
      }, () => {
      }
    );
  }

  onUpdateCheckpointState(evt: any) {
    this.store.dispatch(new RecordPage.UpdateCheckpointState(this.recordId, evt.checkpointId, evt.state));
  }

  onUpdateCheckpointComment(evt: any) {
    this.store.dispatch(new RecordPage.UpdateCheckpointComment(this.recordId, evt.checkpointId, evt.comment));
  }

  onEvaluateCheckpoint(evt: any) {
    this.store.dispatch(new RecordPage.EvaluateCheckpoint(this.recordId, evt.checkpointId, evt.value, evt.state));
  }

  onChecklistRecordSelect(evt) {
    let checklistRecordId = evt.checklistRecordId
    const checklist = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId)).entity.checklistsRecords.find((el) => el.id === checklistRecordId);
    if (checklist && (!checklist.originalCheckpointsets.length || !checklist.originalCheckpointsets.map(cs => cs.checkpoints).flat().length)) {
      this.toastr.error(translate("Vous ne pouvez pas séléctionner une checklist vide") + '.');
      return;
    }
    this.store.dispatch(new RecordPage.UpdateChecklistRecord(this.recordId, checklistRecordId));
  }

  onAddCheckpointAttachment(evt: any) {
    this.store.dispatch(new RecordPage.CreateCheckpointAttachment(
      this.recordId,
      evt.checkpoint,
      evt.file,
      !!evt.isStandard)
    );
  }

  onDeleteCheckpointAttachment(evt: any) {
    const checkpoint = evt.checkpoint ? evt.checkpoint : evt.parent;
    let fileId;
    checkpoint.checkpointAttachments.forEach(attachment => {
      if (stripBaseUrl(attachment.file) === stripBaseUrl(evt.file)) {
        fileId = attachment.id;
      }
    });
    this.store.dispatch(new RecordPage.DestroyCheckpointAttachment(this.recordId, checkpoint, fileId, evt.isStandard));
  }

  onCreateCheckpointFixingRecord(evt: any) {
    this.store.dispatch(new RecordPage.CreateFixingRecord(this.recordId, evt.spaceId, evt.entityId, evt.entityFieldId, evt.title, evt.checkpointId));
  }

  onAttachmentCreate(file: any) {
    this.store.dispatch(new RecordPage.CreateAttachment(this.recordId, file));
  }

  onAttachmentDestroy(id: any) {
    this.store.dispatch(new RecordPage.DestroyAttachment(this.recordId, id));
  }

  safeUrlEncode(value: string): string {
    return value.replace(/\//g, '%2F');
  }

  onTabChange(evt) {
    if (!this.isModal) {
      this.router.navigate(['record', this.recordId, this.safeUrlEncode(evt.nextId)]).then(() => {
      });
    } else {
      this.modalProxyService.openRecordModal(this.recordId, evt.nextId);
    }
  }

  onPrint() {
    $("body").before($(".modal-content"));
    window.print()
    $(".modal-dialog").append($(".modal-content"));
  }

  onRefresh() {
    this.store.dispatch(new RecordPage.ExecuteAction(this.recordId, -1, null)).subscribe(() => {
      this.toastr.success(translate("Actualisé") + '.')
    })
  }

  onGanttDateChange(evt, field) {
    const { mirrorFields: [{ entity: { startingDateField, endingDateField } }] } = field;
    const changes = {
      ...(startingDateField && { [startingDateField.id]: evt.start }),
      ...(endingDateField && { [endingDateField.id]: evt.end }),
    };

    this.recordService.retrieveObject(evt.record).subscribe({
      next: (record: any) => {
        this.store.dispatch(new RecordPage.UpdateRecordFields(record, changes))
          .subscribe({
            error: (e) => e.status === 400 && this.ngOnInit()
          });
      }
    });
  }

  onGanttScopeChange(scope, field) {
    this.store.dispatch(new RecordPage.ChangeGanttScope(this.recordId, scope, field));
  }

  onGanttScroll(evt, field) {
    this.store.dispatch(new RecordPage.RetrieveTabObjects(this.recordId, field, evt.page));
  }

  onModeChange(mode, field) {
    this.store.dispatch(new RecordPage.SwitchMode(this.recordId, mode, field));
  }

  hasPadding(block) {
    const isFieldset = block.fields && block.fields.length > 0 && !block.widgets.length;
    const isNotTabbedMany = block.fields && block.fields[0]?.isMany && !block.isTabbed;
    const hasAuditOrChecklist = block.extensions && ["Audit", "Checklist"].includes(block.extensions[0]?.type);
    return !block.hasChildren && ((isFieldset || isNotTabbedMany || hasAuditOrChecklist) && !this.modalWindow);
  }

  refreshFromWS(data) {
    const content = data.message.response;
    const recordState = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId));
    if (content.staleRecords.includes(+this.recordId)) {
      if (!(this.router.url !== this.location.path() && !this.isModal)) {
        this.reInit$.next({trigger: true});
      }
    } else {
      const recordsMany = _.map(_.flatten(_.map(_.values(recordState.many), r => _.uniqBy(_.concat(r.timelineRecords, r.records), 'id'))), 'id');
      if (_.intersection(recordsMany, content.staleRecords).length) {
        if (!(this.router.url !== this.location.path() && !this.isModal)) {
          this.reInit$.next({trigger: true});
        }
      }
    }
  }

  openDetailPage(evt) {
    openDetailPage(evt, () => {
      this.closeDetailPageModal();
    });
  }

  checkElement = async selector => {
    let frame = 0;
    while (!document.getElementById(selector)) {
      frame++;
      if (frame > 10) {
        throw new Error();
      }
      await new Promise(resolve => requestAnimationFrame(resolve));
    }
    return;
  }

  scrollToTargetAdjusted(elem) {
    const element = document.getElementById(elem);
    const getElementPosition = (el) => {
      const rect = el.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return  rect.top + scrollTop;
    }
    const headerOffset = 140;
    const elementPosition = getElementPosition(element);
    const offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
    setTimeout(() => {
      element.getElementsByClassName('card-body')[0].classList.add('card-highlight');
    }, 500);
  }

  destroyRecord(recordId) {
    if (this.confirmationModal) {
      this.confirmationModal.close();
      this.confirmationModal = null;
    }
    const state = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId));
    this.store.dispatch(new RecordPage.DestroyRecord(recordId)).subscribe(() => {
      const entity = state.entity;
      this.toastr.success(translate("Supprimé", {e: entity.isMasculine ? '' : 'e'}));
      if (!this.isModal) {
        if (window.history.state.navigationId > 1) {
          window.history.back();
        } else {
          // after deleting the record, if its entity has no page, return to the parent record
          const page = state.entity.page;
          const parentRecordId = state.record.parentRecords[0];
          const siderPage = this.store.snapshot().app.sider.pages[0];
          const route = page
            ? `/records/${entity.id}`
            : parentRecordId !== undefined
              ? `/record/${parentRecordId}`
              : siderPage.entity !== null
                ? `/records/${siderPage.entity.id}`
                : `/record/${siderPage.record.id}`;
          this.router.navigate([route]).then(() => {
          });
        }
      } else {
        this.closeDetailPageModal();
      }
    }, () => {
    });
  }

  toggleTag(tag) {
    this.store.dispatch(new RecordPage.ToggleRecordTag(this.recordId, tag));
  }

  getParentScroll(blockId) {
    return document.querySelector('#block-' + blockId);
  }

  onGlobalFilterApply(evt: any) {
    this.widgetsGlobalFilters = evt;
    this.widgetsActiveGlobalFilters = getActiveFilters(evt.rawData, evt.types);
    this.closeReadFormModal();
    const recordState = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId))
    this.dbService.update('widgetsFilters', {
      id: _.toNumber(`${this.recordId}${recordState.entity.id}`),
      filters: evt,
    }).subscribe();
  }

  onClearFiltersClick() {
    this.widgetsGlobalFilters = {};
    this.widgetsActiveGlobalFilters = {};
    const recordState = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId));
    this.dbService.delete('widgetsFilters', _.toNumber(`${this.recordId}${recordState.entity.id}`)).subscribe();
  }

  onWidgetChange(evt: any) {
    const action = evt.action;
    const message = evt.message;
    this.webSocketService.sendMessage(action, message);
  }

  refreshWidgetData(message: any) {
    if (message.response && message.response.hasOwnProperty("error")) {
      this.widgetsErrors[message.widget] = message.response;
      const globalErrorHandler = this.injector.get(AngularErrorHandler);
      if (this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId))?.user.isSuperuser){
        this.widgetsErrors[message.widget].debug = true;
      }
      globalErrorHandler.handleError(new Error(message.response.error));
    } else {
      this.widgetsData[message.widget] = typeof (message.response) == "string" ? new String(message.response) : message.response;
      this.changeDetector.detectChanges();
      this.dbService.update('widgetsData', {
        id: _.toNumber(`${this.recordId}${message.widget}`),
        data: message.response,
      }).subscribe();
      if (_.keys(this.widgetsErrors[message.widget]).length) this.widgetsErrors[message.widget] = {};
    }
  }

  goToAudit(evt: any) {
    const state = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId))
    let auditId = state.record?.fixedCheckpoint.checkpointset.auditRecord;
    let recordId = state.record?.id;
    if (this.isModal) {
      if(this.location.path().includes(auditId)){
        this.closeDetailPageModal();
        this.checkElement('record-' + recordId).then(() => {
          this.scrollToTargetAdjusted('record-' + recordId);
        }).catch(() => {
        });
      } else {
        this.closeDetailPageModal();
        setTimeout(() => {
          this.router.navigate(['record/' + auditId], { fragment: recordId })
        }, 100);
      }
    } else {
      this.router.navigate(['/record/' + auditId], {'fragment': recordId}).then(() => {
        this.closeDetailPageModal();
      });
    }
  }

  computedColumns(cols, gridColumns = 12): string {
    const newcols = computeColumns(cols, gridColumns);
    return computeGridColumnsClasses(newcols);
  }

  panelConfig(resolution: number) {
    this.panZoomConfig.api.pipe((api)=> {this.#takeUntilDestroyed(api); return api;}).subscribe( (api: PanZoomAPI) => this.panZoomAPI = api );
    document.getElementById('pageDetail')?.addEventListener('wheel', (e) => {
      if (e.ctrlKey) {
        e.preventDefault();
        this.panZoomConfig.zoomOnMouseWheel = true;
        setTimeout(() => {
          this.panZoomConfig.zoomOnMouseWheel = false;
        })
      }
    })

    document.querySelectorAll('.pan-element').forEach((element: HTMLElement) => {
      element['style'].maxWidth = '-webkit-fill-available'
      element['style'].minWidth = '-webkit-fill-available'
    });
    if (resolution == 2) {
      this.panZoomConfig.initialZoomLevel = 2 / resolution;
      this.panZoomAPI.resetView();
      this.panZoomConfig.modelChanged.pipe(this.#takeUntilDestroyed).subscribe((model: PanZoomModel) => {
        if (document.getElementsByClassName("zoom-element").length != 0) {
          let innerContainer = <HTMLElement>document.getElementsByClassName("zoom-element")[0];
          let outerContainer = <HTMLElement>document.getElementsByClassName("pan-element")[0];
          if (innerContainer.offsetWidth == outerContainer.offsetWidth - +window.getComputedStyle(outerContainer).paddingInline.slice(0, -2) * 2) innerContainer.style.width = innerContainer.offsetWidth * resolution + "px";
        }
      });
    }
  }

  fullScreenClick() {
    let element = document.getElementById('pageDetail')
    if (this.panelIsFullScreen) {
      document.exitFullscreen();
    } else {
      element.requestFullscreen()
    }

    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        element?.classList.add('app-default')
        element['style'].overflowY = 'scroll';
        this.panelIsFullScreen = true;
      } else {
        this.panelIsFullScreen = false;
        element?.classList.remove('app-default')
      }
    });
  }

  getRelativePosition(inner, outer) {
    const innerRect = inner.getBoundingClientRect();
    const outerRect = outer.getBoundingClientRect();

    return {
      top: innerRect.top - outerRect.top,
      left: innerRect.left - outerRect.left
    };
  }

  recalculateSeparatorsOnBreakpoints(){
    this.breakpointObserver.observe([
      "(min-width: 576px)",
      "(min-width: 768px)",
      "(min-width: 992px)",
      "(min-width: 1200px)"
    ])
    .pipe(this.#takeUntilDestroyed)
    .pipe(debounceTime(100))
    .subscribe((result: BreakpointState) => {
      if (result.matches) {
        const recordState = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId))
        if(recordState.isReady && this.blockElementsRef){
          if (recordState.entity.isPanel) {
            this.setSeparators(this.blockElementsRef);
          }else{
            const widgets = _.flatMap(recordState.entity.blocks, (b) => b.widgets);
            this.setSeparatorsForTabbedBlocksWidgets(widgets);
          }
        }
      }
    });
  }

  setSeparatorsForTabbedBlocksWidgets(stateWidgets){
    const widgetIdsForTabbedBlocks = this.getBlocksWidgetIds(this.tabbedBlocksWithFieldsOrWidgets);
    const widgetsRefs = this.getWidgetRefsForTabbedBlocks(stateWidgets, widgetIdsForTabbedBlocks);
    this.setSeparators(widgetsRefs);
  }

  setSeparators(blockElementsRef) {
    blockElementsRef.forEach((ref) => {
      const relativePos = this.getRelativePosition(ref.nativeElement, ref.nativeElement.parentNode);
      let leftSeparator = ref.nativeElement.querySelector(".left-separator");
      if (leftSeparator) leftSeparator.hidden = !((relativePos.left > 50) && leftSeparator);
      let topSeparator = ref.nativeElement.querySelector(".top-separator");
      if (topSeparator) topSeparator.hidden = !((relativePos.top > 50) && topSeparator);
    });
  }

  getBlocksWidgetIds(blocks: any[]){
    return _.map( _.flatMap( _.map(blocks, b => b.widgets) ) ,  w => w?.id);
  }

  getWidgetRefsForTabbedBlocks(stateWidgets, widgetIds) {
    return this.blockElementsRef
      .filter((ref: ElementRef) => {
        const elemId = ref.nativeElement.id?.split('-')[1];
        return stateWidgets[elemId] && widgetIds.includes(Number(elemId))
      })
  }

  zoomIn() {
    this.panZoomAPI?.zoomIn();
  }

  zoomOut() {
    this.panZoomAPI?.zoomOut();
  }

  resetView() {
    this.panZoomAPI?.resetView();
  }

  closeDetailPageModal(reason = '') {
    this.closeModal.emit(reason);
  }

  toggleCollapse(block) {
    let isBlockCollapsed = !this.localService.getCollapsedState(block.id, this.recordId);
    this.localService.setCollapsedState({recordId: this.recordId, blockId: block.id, collapsed: isBlockCollapsed})
  }

  isWidget(element: any) {
    return widgets.includes(element?.type);
  }

  isTitleActive(element, block: any) {
    return element.title && block.isTabbed && !element.hasOwnProperty('isMany') && !this.isWidget(element) && (block.widgets.length + block.fields.length + block.extensions.length) > 1
  }

  isCollapsed(blockId): boolean {
    return this.localService.getCollapsedState(blockId, this.recordId);
  }

  isCardBlock(block: any) {
    return block.isCard && !block.block?.isCard;
  }

  getBlockWidgetsNunmber(block: any, withCommunicationWidget=true) {
    return withCommunicationWidget?block.widgets.length:block.widgets.filter(widget => widget.type !== 'communication').length
  }

  onPanzoomEvent($event: any) {
    if (isTouchScreen()) return;
    const isCtrl = !!$event.ctrlKey;
    if (isCtrl != this.panZoomConfig.panOnClickDrag) {
      this.panZoomConfig.panOnClickDrag = isCtrl;
      this.panZoomConfig.noDragFromElementClass = isCtrl ? null : 'block';
    }
  }

  openWidgetFormModal(widgetFormRef, form) {
      this.readForm = form;
      this.formModal = this.modalService.open(widgetFormRef, {size: 'lg'});
  }

  blockHasCommunicationWidget(block: any): boolean {
    return block.widgets.some(el => el.type === 'communication');
  }

  onSiderBlockOpen(evt, content: TemplateRef<any>, block: any){
    this.activeSiderBlock = block;
    this.store.dispatch(new RecordPage.LoadSiderBlock(
        this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId)).record.id,
        block.id
    ))
    this.offcanvasService.open(content, { position: 'end'}).result.then(
      (result) => {},
      (reason) => {this.activeSiderBlock = null;},
    );
  }

  isTabbedWithFieldsOrWidgets(block): boolean{
    return _.find(this.tabbedBlocksWithFieldsOrWidgets, b => b.id === block.id)
  }

  onHiddenTabsUpdated(parentBlockId, hiddenTabs){
    hiddenTabs = hiddenTabs.map(hiddenTab => hiddenTab.querySelector(`.${PrioritynavSelectors.LINK}`).dataset.tab);
    this.hiddenTabs[parentBlockId] = hiddenTabs;
  }

  closeReadFormModal(){
    this.formModal.close();
  }

   isArray(flatBlock){
    return Array.isArray(flatBlock);
   };
}
